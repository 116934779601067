<template>
  <div class="orderIndexView content-index">
    <div class="collView listView">
      <el-row style="display:flex">
        <el-col :style="enablePiecework?'flex:0.9':'flex:1'">
          <div
            class="view-left"
            v-loading="loading"
            :style="
              enablePiecework ? 'margin-right: 15px;' : 'margin-right: 0;'
            "
          >
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>今日营业数据</span>
              </div>
              <el-row :gutter="15" class="todayTop">
                <el-col :span="8">
                  <el-card
                    :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                    shadow="hover"
                    @click.native="$router.push('/home/order')"
                  >
                    <div slot="header" class="clearfix">
                      <span class="clearfixValue">今日收衣</span>
                    </div>
                    <div class="clearfixItem">
                      <div style="margin-right: 50px;">
                        {{ homepageData.todayGetOrderNum }}单
                      </div>
                      <div>{{ homepageData.todayGetOrderClothesNum }}件</div>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card
                    :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                    shadow="hover"
                    @click.native="goPath('alreadyTake')"
                  >
                    <div slot="header" class="clearfix">
                      <span class="clearfixValue">今日取衣</span>
                    </div>
                    <div class="clearfixItem">
                      <div style="margin-right: 50px;">
                        {{ homepageData.todayPickOrderNum }}单
                      </div>
                      <div>{{ homepageData.todayPickOrderClothesNum }}件</div>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card
                    :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                    shadow="hover"
                    @click.native="
                      $router.push('/home/todayData/todayNetReceipts')
                    "
                  >
                    <div slot="header" class="clearfix">
                      <span class="clearfixValue">今日实收</span>
                    </div>
                    <div>{{ homepageData.todayActually }}元</div>
                  </el-card>
                </el-col>
              </el-row>
              <el-row :gutter="15" class="todayBtm">
                <el-col :span="8">
                  <el-card
                    :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                    shadow="hover"
                    @click.native="
                      $router.push('/home/todayData/todayRechange')
                    "
                  >
                    <div slot="header" class="clearfix">
                      <span class="clearfixValue">今日充值</span>
                    </div>
                    <div class="clearfixItem">
                      <div style="margin-right: 50px;">
                        {{ homepageData.todayRechargeNum }}笔
                      </div>
                      <div>{{ homepageData.todayRecharge }}元</div>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card
                    :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                    shadow="hover"
                    @click.native="$router.push('/home/todayData/todayNewCard')"
                  >
                    <div slot="header" class="clearfix">
                      <span class="clearfixValue">今日办卡</span>
                    </div>
                    <div>{{ homepageData.applyCardNum }}张</div>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card
                    :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                    shadow="hover"
                    @click.native="$router.push('/home/todayData/todayRefund')"
                  >
                    <div slot="header" class="clearfix">
                      <span class="clearfixValue">今日退款</span>
                    </div>
                    <div>{{ homepageData.todayRefund }}元</div>
                  </el-card>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-col>
        <el-col style="flex:1.1" v-if="enablePiecework">
          <div class="view-right" v-loading="commissionLoading">
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span class="cent" @click="goPath('myCommission')"
                  >今日我的提成</span
                >
                <span
                  v-if="homepageData.storeScore != null"
                  class="header-item"
                  @click="goshop('StoreManagerPoints')"
                  >店长分:{{ homepageData.storeScore }}分
                  <span class="itemday"
                    >今日:
                    <span
                      :style="{
                        color:
                          homepageData.storeScoreChange > 0 ? 'red' : '#fff',
                      }"
                    >
                      <span v-if="homepageData.storeScoreChange > 0"
                        ><i class="el-icon-top"></i>+</span
                      >
                      <span v-if="homepageData.storeScoreChange < 0"
                        ><i class="el-icon-bottom"></i
                      ></span>
                      {{ homepageData.storeScoreChange }}
                    </span>
                  </span>
                </span>
                <span v-else></span>
                <span>
                  今日排名: 第
                  {{ commissionData.ranking ? commissionData.ranking : "1" }}
                  名
                </span>
              </div>
              <div @click="goPath('myCommission')">
                <el-row  class="commission">
                  <el-col style="flex:1;margin-right:15px">
                    <el-card
                      :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                      shadow="hover"
                    >
                      <div slot="header" class="clearfix">
                        <span class="clearfixValue">今日收衣</span>
                      </div>
                      <div class="clearfixCtn">
                        <p>预计提成</p>
                        <p>
                          {{ commissionData.waitPrice }}
                          <span>元</span>
                        </p>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col style="flex:1;margin-right:15px">
                    <el-card
                      :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                      shadow="hover"
                    >
                      <div slot="header" class="clearfix">
                        <span class="clearfixValue">今日办卡</span>
                      </div>
                      <div class="clearfixCtn">
                        <p>确认提成</p>
                        <p>
                          {{ commissionData.cardPrice }}
                          <span>元</span>
                        </p>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col style="flex:1;margin-right:15px">
                    <el-card
                      :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                      shadow="hover"
                    >
                      <div slot="header" class="clearfix">
                        <span class="clearfixValue">今日充值</span>
                      </div>
                      <div class="clearfixCtn">
                        <p>确认提成</p>
                        <p>
                          {{ commissionData.rechargePrice }}
                          <span>元</span>
                        </p>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col style="flex:1;margin-right:15px">
                    <el-card
                      :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                      shadow="hover"
                    >
                      <div slot="header" class="clearfix">
                        <span class="clearfixValue">今日取衣</span>
                      </div>
                      <div class="clearfixCtn">
                        <p>确认提成</p>
                        <p>
                          {{ commissionData.tackPrice }}
                          <span>元</span>
                        </p>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col style="flex:1;margin-right:15px">
                    <el-card
                      :body-style="{ textAlign: 'center', cursor: 'pointer' }"
                      shadow="hover"
                    >
                      <div slot="header" class="clearfix">
                        <span class="clearfixValue">今日商品</span>
                      </div>
                      <div class="clearfixCtn">
                        <p>确认提成</p>
                        <p>
                          {{ commissionData.goodsPrice }}
                          <span>元</span>
                        </p>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="topView" style="padding: 0 0;">
      <div class="tView" style="font-size: 20px;">快捷入口</div>
    </div>
    <div class="collView listView footerDistance">
      <el-row :gutter="10" style="margin-left: 0; margin-right: 0;">
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/get/query' })"
          >
            <img style="width: 80px;" :src="clothes" />
            <p>收衣</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/more/sendWash' })"
          >
            <img style="width: 80px;" :src="jianbianffe" />
            <p>送洗</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/more/enterShop' })"
          >
            <img style="width: 80px;" :src="shop" />
            <p>入店</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/more/shelvesIndex' })"
          >
            <img style="width: 80px;" :src="yijia" />
            <p>上挂</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="
              $router.push({ path: '/home/more/takeClothesIndex' })
            "
          >
            <img style="width: 80px;" :src="takeexpress" />
            <p>取衣</p>
          </el-card>
        </el-col>
      </el-row>
      <el-row
        :gutter="10"
        style="margin-top: 10px; margin-left: 0; margin-right: 0;"
      >
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/applyCard' })"
          >
            <img style="width: 80px;" :src="card" />
            <p>办卡</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/charger' })"
          >
            <img style="width: 80px;" :src="rcharge" />
            <p>充值</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/user' })"
          >
            <img style="width: 80px;" :src="user" />
            <p>用户</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/order' })"
          >
            <img style="width: 80px;" :src="order" />
            <p>订单</p>
          </el-card>
        </el-col>
        <el-col :span="25">
          <el-card
            :body-style="{
              textAlign: 'center',
              cursor: 'pointer',
              height: '130px',
              boxSizing: 'content-box',
            }"
            shadow="hover"
            @click.native="$router.push({ path: '/home/more/rewashing' })"
          >
            <img style="width: 80px;" :src="fan" />
            <p>返洗</p>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 音频 -->
    <audio controls id="audioPlay" ref="audio" hidden>
      <source src="../../assets/audio/newmsg.mp3" type="audio/mpeg" />
    </audio>
    <pop-view
      id="refundViewPopView"
      :show="showModal"
      width="500px"
      top="30%"
      :title="'hi~' + userInfo.realName + '请你登录确认哦'"
      @cancel="showModal = false"
    >
      <div style="display: table; margin: 0 auto;">
        <div>店名: {{ userInfo.storeName }}</div>
        <div>账号: {{ userInfo.phone }}</div>
        <div>店长: {{ userInfo.realName }}</div>
      </div>

      <div
        style="
          display: flex;
          text-align: center;
          align-content: center;
          justify-content: center;
          margin-top: 10px;
        "
      >
        <el-button type="danger" size="small" @click="handleAction('cancel')">
          重新登录
        </el-button>
        <el-button type="primary" size="small" @click="handleAction('yes')">
          确认登录
        </el-button>
      </div>
    </pop-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
import store from "../../store";
import takeexpress from "_img/take-express_fill.png";
import caogaoxiang from "_img/caogaoxiang.png";
import yijia from "_img/yijia.png";
import clothes from "_img/clothes.png";
import rcharge from "_img/rcharge.jpg";
import card from "_img/card.jpg";
import fan from "_img/fan.png";
import user from "_img/user.png";
import order from "_img/order.png";
import jianbianffe from "_img/wuliu-jianbianffe_ffdf_px.png";
import shop from "_img/shop.png";

import { getHomepageData } from "@/api/clothes/order";
import { getEnablePiecework } from "@/api/user";
import PopView from "@/components/PopView";
import { dateFormat } from "@/utils/date";
export default {
  name: "index",
  components: {
    PopView,
  },
  data() {
    return {
      takeexpress,
      caogaoxiang,
      yijia,
      clothes,
      card,
      rcharge,
      fan,
      user,
      order,
      jianbianffe,
      shop,
      notifyPromise: Promise.resolve(),
      notifications: {},
      reserveOrderNum: 0,
      draftboxClothesNum: 0,
      showModal: false,
      homepageData: {},
      tenantId: "",
      timeStart: dateFormat(new Date()),
      timeEnd: dateFormat(new Date()),
      loading: false,
      orderRemNum: 0, //催单订单数量
      commissionData: {},
      commissionLoading: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "enablePiecework"]),
  },
  created() {
    this.getStoreListByUserName();
    if (this.$route.params.visible) {
      this.handleLogin();
    }
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch("user/getEnablePieceworkData");
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    //关闭单个通知
    closeNotification(id, operateCode, message) {
      this.notifications[message.messageId].close();
      delete this.notifications[message.messageId];
    },

    //关闭所有通知
    closeAllNotification() {
      let _this = this;
      for (let key in _this.notifications) {
        _this.notifications[key].close();
        delete _this.notifications[key];
      }
    },

    //打开一个新的通知
    openMessageTips(message) {
      let _this = this;
      this.closeAllNotification();
      //将messageId和通知实例放入字典中
      this.notifications[message.messageId] = this.$notify({
        title: "订单通知",
        position: "top-right",
        showClose: false,
        dangerouslyUseHTMLString: true,
        message: this.$createElement(
          "div",
          {
            style: {
              width: "285px",
            },
          },
          [
            this.$createElement("div", null, [
              this.$createElement("span", null, message.content),
            ]),
            this.$createElement(
              "div",
              {
                style: {
                  textAlign: "right",
                },
              },
              [
                this.$createElement(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                    },
                    on: {
                      click: _this.closeNotification.bind(_this, 1, 1, message),
                    },
                  },
                  "暂不处理"
                ),
                this.$createElement(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                    },
                    style: {
                      marginLeft: "20px",
                    },
                    on: {
                      click: _this.handleReserve(),
                    },
                  },
                  "查看详情"
                ),
              ]
            ),
          ]
        ),
        duration: 0,
      });
    },
    // 语音播放
    aplayAudio() {
      let num = this.reserveOrderNum;
      this.reserveOrderNum = num + 1;
      var audio = document.getElementById("audioPlay");
      audio.play();
    },
    handleReserve() {
      console.log("111");
      this.$router.push({ path: "/home/reserve" });
    },
    handleLogin() {
      this.showModal = true;
    },
    handleAction(action) {
      this.showModal = false;
      if (action === "yes") {
        // 确认
        this.$router.push({ path: "/home/get" });
        this.$notify.success({
          title: "登录提示",
          message: "亲爱的店长恭喜你,登录成功",
        });
      } else {
        // 重新登录
        this.$store.dispatch("user/logout").then(() => {
          location.reload();
        });
      }
    },
    getStoreListByUserName() {
      if (getToken()) {
        const userInfo = store.getters.userInfo;
        this.tenantId = userInfo.storeId;
        this.getHomepageData();
      }
    },
    getHomepageData() {
      this.loading = true;
      this.commissionLoading = true;
      const params = new URLSearchParams();
      params.append("beginTime", this.timeStart);
      params.append("endTime", this.timeEnd);
      params.append("tenantId", this.tenantId);
      getHomepageData(params)
        .then((response) => {
          this.loading = false;
          this.commissionLoading = false;
          if (response.data.code === 0) {
            this.homepageData = response.data.data;
            this.commissionData = response.data.data.commissionDetailsVo;
            console.log("aaaaaaaaaaaaa", response);
          }
        })
        .catch(() => {
          this.loading = false;
          this.commissionLoading = false;
        });
    },
    handleX() {
      this.$message.error("尚未开发完成");
    },
    // 路由跳转
    goPath(path) {
      this.$router.push({ name: path });
    },
    goshop(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped lang="scss">
.cent {
  position: relative;
  text-align: center;
}
::v-deep .el-col-25 {
  width: 20%;
}

::v-deep .el-card__body {
  padding: 10px;
}
.view-left {
  ::v-deep .el-card__header {
    padding: 10px 20px;
    background-color: #3370ff;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }
  .todayTop {
    margin-bottom: 15px;
    ::v-deep .el-card__header {
      padding: 10px 20px;
      background-color: #fff;
      color: #000;
    }
  }
  .todayBtm {
    ::v-deep .el-card__header {
      padding: 10px 20px;
      background-color: #fff;
      color: #000;
    }
  }
}
.view-right {
  ::v-deep .el-card__header {
    padding: 10px 20px;
    background-color: #3370ff;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    .clearfix {
      position: relative;
      span:nth-child(2) {
        position: absolute;
        top: 0;
      }
      span:nth-child(3) {
        position: absolute;
        right: 10px;
        font-size: 16px;
        font-weight: 400;
      }
      .header-item {
        display: flex;
        font-size: 12px;
        flex-direction: column;
      }
      .clearfix {
        text-align: center;
        > .clearfixValue {
          margin-left: 100px;
        }
      }
    }
  }
  .commission {
    position: relative;
    padding: 10px 0;
    display: flex;
    ::v-deep .el-card__header {
      background-color: #fff;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      // padding-left: 30%;
    }
  }
  .clearfixCtn {
    p:nth-child(2) {
      margin: 18px 0;
      font-size: 26px;
      font-weight: 700;
      span {
        font-size: 16px;
      }
    }
  }
}
.orderIndexView {
  overflow: auto;
  position: relative;
  padding: 0 30px 0 20px;

  .topView {
    padding-top: 15px;
    overflow: hidden;
    z-index: 101;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 24px;
      line-height: 30px;
    }

    .storeInfo {
      position: relative;
      float: right;
      line-height: 40px;
      display: flex;
    }

    .orderBtn {
      position: relative;
      float: right;
      line-height: 40px;
      min-width: 80px;
      height: 40px;
      font-family: SFUIDisplay-Regular;
      font-size: 18px;
      color: #ffffff;
      padding: 0;
      background: #3370ff;
      z-index: 100;
      border-radius: 5px;
    }
  }

  > .collView {
    padding: 10px 0 10px 0;
    overflow: auto;
    .clearfix {
      text-align: center;
      > .clearfixValue {
        margin-left: 10px;
      }
    }

    .clearfixItem {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.proposal {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  padding: 8px 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  border-radius: 5px 0 0 5px;
  background-color: #0086ff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.footer {
  background-color: rgb(248, 248, 248);
  color: rgb(51, 51, 51);
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  flex-direction: row-reverse;
  line-height: 60px;

  .container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    > * {
      margin: 0 15px;
      height: 40px;
      line-height: 40px;
    }

    img {
      width: 20px;
    }
  }
}
.footerDistance {
  // margin-bottom: 60px;
}
</style>
